import { Loan, MyApplication, Onboarding, TermsSuggestion, Underwriting } from '../models/application';
import { createEndpoint } from '@getmo/common/endpoint';
import { Nullable } from '@getmo/common/typebox';
import { Type } from '@getmo/common/vendor/typebox';

export const listApplicationsEndpoint = createEndpoint({
  method: 'get',
  path: '/applications',
  response: Type.Object({
    defaultId: Onboarding.properties.id,
    onboardings: Type.Array(
      Type.Composite([
        Onboarding,
        Type.Object({
          underwriting: Nullable(
            Type.Composite([
              Underwriting,
              Type.Object({
                loan: Nullable(Loan),
                selectedTerms: Nullable(TermsSuggestion),
              }),
            ]),
          ),
        }),
      ]),
    ),
  }),
});

export const getApplicationEndpoint = createEndpoint({
  method: 'get',
  path: '/application',
  qs: Type.Object({
    onboardingId: Type.Optional(Type.Number()),
  }),
  response: MyApplication,
});

export const sendWhatsappOtp = createEndpoint({
  method: 'post',
  path: '/auth/otp/send',
  anonymous: true,
  body: Type.Object({
    phone: Type.String(),
  }),
  response: Type.Object({
    sessionId: Type.Optional(Type.String()),
  }),
});

export enum LoginStatus {
  NoSuchUser = 'no-such-user',
  OtpInvalid = 'otp-invalid',
  Success = 'success',
}
export const verifyWhatsappOtp = createEndpoint({
  method: 'post',
  path: '/auth/otp/verify',
  anonymous: true,
  body: Type.Object({
    sessionId: Type.Optional(Type.String()),
    phone: Type.String(),
    otp: Type.String(),
  }),
  response: Type.Object({
    status: Type.Enum(LoginStatus),
  }),
});

export const logoutEndpoint = createEndpoint({
  method: 'get',
  path: '/logout',
  response: Type.Object({}),
});
